<template>
  <v-container fluid>
    <validation-observer v-slot="{ invalid }">
      <v-card>
        <v-card-title>お友達紹介プログラム</v-card-title>
        <v-card-subtitle
          >氏名と電話番号を入力して、あなた専用のQRコードを作成してください。面接を受けたいお友達にQRコードやURLをシェアしてください。😊</v-card-subtitle
        >
        <v-card-text>
          <v-row>
            <v-col>
              <validation-provider
                name="紹介者（あなた）の氏名"
                rules="required"
              >
                <template v-slot="{ errors }">
                  <v-text-field
                    v-model="name"
                    label="紹介者（あなた）の氏名"
                    placeholder="氏名を入力してください"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </template>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                name="紹介者（あなた）の電話番号"
                :rules="{ required: true, min: 10, regex: /^[0-9\-]{10,}$/ }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="telno"
                  label="紹介者（あなた）の電話番号"
                  placeholder="電話番号を入力してください"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn @click="createQRCode" :disabled="invalid"
                >QRコードを作成</v-btn
              >
            </v-col>
          </v-row>
          <v-row justify="center" v-if="isCreatingQRCode">
            <v-col cols="auto" align-self="center">
              <v-progress-circular
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="auto" align-self="center">QRコードの作成中...</v-col>
          </v-row>
          <v-row
            justify="center"
            v-if="!isCreatingQRCode && !isReferralUrlEmpty"
          >
            <VueQr :dotScale="0.5" :text="referralUrl" />
          </v-row>
          <v-row
            justify="center"
            v-if="!isCreatingQRCode && !isReferralUrlEmpty"
          >
            <v-col cols="auto">
              <a :href="referralUrl">{{ referralUrl }}</a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </validation-observer>
  </v-container>
</template>

<script>
import { encodeCampaignInfo } from '@/helpers/campaign-info'
import { createStaff, updateStaff, addQRCode } from '@/helpers/firebase-functions'
import { shrinkUrl } from '@/helpers/referral'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueQr from 'vue-qr'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueQr
  },
  data: () => ({
    name: '',
    telno: '',
    referralUrl: '',
    isCreatingQRCode: false
  }),
  computed: {
    appSociallyColor () {
      return '#FFD54F'
    },
    isReferralUrlEmpty () {
      return this.referralUrl === ''
    }
  },
  methods: {
    getStaffData () {
      const staffData = localStorage.getItem('staffData')
      if (staffData) {
        const { name, telno, referralUrl } = JSON.parse(staffData)
        this.name = name
        this.telno = telno
        this.referralUrl = referralUrl
      }
    },
    setStaffData () {
      const staffData = { name: this.name, telno: this.telno, referralUrl: this.referralUrl }
      localStorage.setItem('staffData', JSON.stringify(staffData))
    },
    async createQRCode () {
      this.isCreatingQRCode = true

      const ownerId = this.$route.params.ownerId
      const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

      const { data: { staffId, referralUrl } } = await createStaff({ ownerId, ownerScenarioId, data: { name: this.name, telno: this.telno } })

      if (referralUrl) {
        this.referralUrl = referralUrl
      } else {
        const absoluteUrl = window ? window.location.origin : ''
        const campaignInfo = encodeCampaignInfo({ ownerId, ownerScenarioId, campaignId: `${staffId}`, campaignType: 'referral' })
        this.referralUrl = await shrinkUrl(`${absoluteUrl}/jobs/${ownerId}/${ownerScenarioId}?campaignInfo=${campaignInfo}`)

        const qrCode = {
          label: `${this.name}`,
          url: this.referralUrl,
          enabled: true,
          count: 0,
          qrCodeId: `${staffId}`,
          qrCodeType: 'referral'
        }
        addQRCode({ ownerId, scenarioId: ownerScenarioId, qrCode })

        updateStaff({ ownerId, ownerScenarioId, staffId, data: { referralUrl: this.referralUrl } })
      }

      this.setStaffData()

      this.isCreatingQRCode = false
    }
  },
  created () {
    this.getStaffData()
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_STAFF)
  }
}
</script>
